import { baseApiRoutes } from '../../core/config/apiRoutes';

export const apiRoutes = {
  ...baseApiRoutes,
  employees: {
    get: 'employees/:id',
    list: 'employees',
    create: 'employees',
    update: 'employees/:id',
    delete: 'employees/:id',
    rolesOptions: 'employees/role-options',
    typesOptions: 'employees/type-options',
    options: 'employees/options',
    optionsByJobTitle: 'employees/options/job-title/:id',
    bulkDelete: 'employees/bulk-delete',
  },
  jobTitles: {
    get: 'job-titles/:id',
    list: 'job-titles',
    options: 'job-titles/options',
    create: 'job-titles',
    update: 'job-titles/:id',
    delete: 'job-titles/:id',
  },
  documents: {
    get: 'documents/:id',
    list: 'documents',
    options: 'documents/options',
    create: 'documents',
    update: 'documents/:id',
    delete: 'documents/:id',
    bulkDelete: 'documents/bulk-delete',
  },
  mainTasks: {
    get: 'main-tasks/:id',
    list: 'main-tasks',
    create: 'main-tasks',
    delete: 'main-tasks/:id',
  },
  tasks: {
    get: 'tasks/:id',
    list: 'tasks',
    getSignAvailability: 'tasks/:id/sign-availability',
    signInitiate: 'tasks/:id/sign-initiate',
    sign: 'tasks/:id/sign',
  },
  taskDocuments: {
    get: 'task-documents/:id',
    list: 'task-documents',
    getFile: 'task-documents/:id/file',
    updateDocumentAsRead: 'task-documents/:id/read',
  },
  transcripts: {
    get: 'transcripts/:id',
    list: 'transcripts',
    create: 'transcripts',
  },
};
