import React from 'react';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { routes } from '../routes/routes';
import { apiRoutes } from '../api/apiRoutes';
import TaskIcon from '@mui/icons-material/Task';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import EngineeringIcon from '@mui/icons-material/Engineering';
import BadgeIcon from '@mui/icons-material/Badge';
import InventoryIcon from '@mui/icons-material/Inventory';
import PlayLessonIcon from '@mui/icons-material/PlayLesson';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBox from '@mui/icons-material/AccountBox';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';

export const companySidebarItems = [
  {
    title: 'SIDEBAR.GROUP_COMPANY_MANAGEMENT',
    items: [
      {
        title: 'SIDEBAR.MAIN_TASKS',
        icon: <TaskAltIcon />,
        url: routes.admin.mainTasks.list,
        apiUrl: apiRoutes.mainTasks.list,
      },
      {
        title: 'SIDEBAR.TASKS',
        icon: <TaskIcon />,
        url: routes.admin.tasks.list,
        apiUrl: apiRoutes.tasks.list,
      },
      {
        title: 'SIDEBAR.DOCUMENTS',
        icon: <DocumentScannerIcon />,
        url: routes.admin.documents.list,
        apiUrl: apiRoutes.documents.list,
      },
      {
        title: 'SIDEBAR.JOB_TITLES',
        icon: <EngineeringIcon />,
        url: routes.admin.jobTitles.list,
        apiUrl: apiRoutes.jobTitles.list,
      },
      {
        title: 'SIDEBAR.EMPLOYEES',
        icon: <BadgeIcon />,
        url: routes.admin.employees.list,
        apiUrl: apiRoutes.employees.list,
      },
      {
        title: 'SIDEBAR.TRANSCRIPTS',
        icon: <InventoryIcon />,
        url: routes.admin.transcripts.list,
      },
    ],
  },
];

export const sidebarItems = [
  {
    title: 'SIDEBAR.GROUP_SETTINGS',
    items: [
      {
        title: 'SIDEBAR.VIDEO_TUTORIALS',
        icon: <PlayLessonIcon />,
        url: routes.admin.videoTutorials.list,
      },
      {
        title: 'SIDEBAR.COMPANIES',
        icon: <BusinessIcon />,
        url: routes.admin.companies.list,
        apiUrl: apiRoutes.companies.list,
        enabled: process.env.REACT_APP_PLATFORM_ENABLED === 'true',
      },
      {
        title: 'SIDEBAR.USERS',
        icon: <AccountBox />,
        url: routes.admin.users.list,
        apiUrl: apiRoutes.users.list,
      },
      {
        title: 'SIDEBAR.TRANSLATIONS',
        icon: <SpellcheckIcon />,
        url: routes.admin.translations.list,
        apiUrl: apiRoutes.translations.list,
      },
    ],
  },
];
