import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import {
  LayoutFactory,
  PageLayoutType,
} from '../../../core/layout/LayoutFactory';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  authTokenState,
  roleAccessState,
} from '../../../core/atoms/auth/auth.atom';
import { routes } from '../../routes/routes';
import LazyLoad from '../../../core/common/LazyLoading/LazyLoading';
import NotFoundPage from '../../../core/pages/error/NotFoundPage/NotFoundPage';
import { useMutation } from '@tanstack/react-query';
import axios, { SuccessResponse } from '../../../core/libraries/axios/axios';
import { MappedRoleAccess } from '../../../core/domain/Role';
import { DefaultError } from '@tanstack/query-core/build/modern/index';
import { apiRoutes } from '../../api/apiRoutes';
import {
  ProgressFactory,
  ProgressVariant,
} from '../../../core/common/Progress/ProgressFactory';

/* eslint-disable new-cap */
const Dashboard = LazyLoad(
  () => import('../../../core/pages/admin/DashboardPage/DashboardPage'),
);
const UsersPage = LazyLoad(
  () => import('../../../core/pages/admin/User/UsersPage/UsersPage'),
);
const UserCreatePage = LazyLoad(
  () => import('../../../core/pages/admin/User/UserCreatePage/UserCreatePage'),
);
const UserEditPage = LazyLoad(
  () => import('../../../core/pages/admin/User/UserEditPage/UserEditPage'),
);
const TranslationsPage = LazyLoad(
  () =>
    import(
      '../../../core/pages/admin/Translation/TranslationsPage/TranslationsPage'
    ),
);
const ProfilePage = LazyLoad(
  () => import('../../../core/pages/admin/Profile/ProfilePage/ProfilePage'),
);
const SettingsPage = LazyLoad(
  () => import('../../../core/pages/admin/Settings/SettingsPage/SettingsPage'),
);

const LoginPage = LazyLoad(
  () => import('../../../core/pages/public/LoginPage/LoginPage'),
);
const PasswordResetPage = LazyLoad(
  () =>
    import('../../../core/pages/public/PasswordResetPage/PasswordResetPage'),
);
const PasswordResetConfirmationPage = LazyLoad(
  () =>
    import(
      '../../../core/pages/public/PasswordResetConfirmationPage/PasswordResetConfirmationPage'
    ),
);
const RegistrationConfirmationPage = LazyLoad(
  () =>
    import(
      '../../../core/pages/public/RegistrationConfirmationPage/RegistrationConfirmationPage'
    ),
);
const DeletionConfirmationPage = LazyLoad(
  () =>
    import(
      '../../../core/pages/public/DeletionConfirmationPage/DeletionConfirmationPage'
    ),
);

const CompaniesPage = LazyLoad(
  () => import('../../../core/pages/admin/Company/CompaniesPage/CompaniesPage'),
);
const CompanyCreatePage = LazyLoad(
  () =>
    import(
      '../../../core/pages/admin/Company/CompanyCreatePage/CompanyCreatePage'
    ),
);
const CompanyEditPage = LazyLoad(
  () =>
    import('../../../core/pages/admin/Company/CompanyEditPage/CompanyEditPage'),
);

const DocumentsPage = LazyLoad(
  () => import('../../../app/pages/admin/Document/DocumentsPage/DocumentsPage'),
);

const EmployeesPage = LazyLoad(
  () => import('../../../app/pages/admin/Employee/EmployeesPage/EmployeesPage'),
);
const EmployeeCreatePage = LazyLoad(
  () =>
    import(
      '../../../app/pages/admin/Employee/EmployeeCreatePage/EmployeeCreatePage'
    ),
);
const EmployeeEditPage = LazyLoad(
  () =>
    import(
      '../../../app/pages/admin/Employee/EmployeeEditPage/EmployeeEditPage'
    ),
);

const JobTitlesPage = LazyLoad(
  () => import('../../../app/pages/admin/JobTitle/JobTitlesPage/JobTitlesPage'),
);
const JobTitleCreatePage = LazyLoad(
  () =>
    import(
      '../../../app/pages/admin/JobTitle/JobTitleCreatePage/JobTitleCreatePage'
    ),
);
const JobTitleEditPage = LazyLoad(
  () =>
    import(
      '../../../app/pages/admin/JobTitle/JobTitleEditPage/JobTitleEditPage'
    ),
);

const MainTasksPage = LazyLoad(
  () => import('../../../app/pages/admin/MainTask/MainTasks/MainTasksPage'),
);
const VideoTutorialsPage = LazyLoad(
  () =>
    import(
      '../../../app/pages/admin/VideoTutorial/VideoTutorialsPage/VideoTutorialsPage'
    ),
);

const TasksPage = LazyLoad(
  () => import('../../../app/pages/admin/Task/TasksPage/TasksPage'),
);
const TaskPage = LazyLoad(
  () => import('../../../app/pages/admin/Task/TaskPage/TaskPage'),
);

const TranscriptsPage = LazyLoad(
  () =>
    import(
      '../../../app/pages/admin/Transcript/TranscriptsPage/TranscriptsPage'
    ),
);
/* eslint-enable new-cap */

export const RouteProvider = () => {
  const authStateValue = useRecoilValue(authTokenState);
  const setRoleAccessStateValue = useSetRecoilState(roleAccessState);

  const { mutate: getMappedRoleAccess, isPending } = useMutation<
    SuccessResponse<MappedRoleAccess[]>,
    DefaultError
  >({
    mutationFn: () => axios.get(apiRoutes.roles.mappedAccess),
    onSuccess: (response) => {
      setRoleAccessStateValue(response.data);
    },
  });

  useEffect(() => {
    if (authStateValue) {
      getMappedRoleAccess();
    }
  }, [authStateValue]);

  const adminPages = useMemo(() => {
    if (authStateValue) {
      return (
        <>
          <Route path={routes.admin.dashboard} element={<Dashboard />} />
          <Route path={routes.homepage} element={<Dashboard />} />
          <Route path={routes.admin.users.list} element={<UsersPage />} />
          <Route path={routes.admin.users.new} element={<UserCreatePage />} />
          <Route path={routes.admin.users.edit} element={<UserEditPage />} />
          <Route path={routes.admin.profile} element={<ProfilePage />} />
          <Route path={routes.admin.settings} element={<SettingsPage />} />
          <Route
            path={routes.admin.companies.list}
            element={<CompaniesPage />}
          />
          <Route
            path={routes.admin.companies.new}
            element={<CompanyCreatePage />}
          />
          <Route
            path={routes.admin.companies.edit}
            element={<CompanyEditPage />}
          />
          <Route
            path={routes.admin.documents.list}
            element={<DocumentsPage />}
          />
          <Route
            path={routes.admin.employees.list}
            element={<EmployeesPage />}
          />
          <Route
            path={routes.admin.employees.new}
            element={<EmployeeCreatePage />}
          />
          <Route
            path={routes.admin.employees.edit}
            element={<EmployeeEditPage />}
          />
          <Route
            path={routes.admin.jobTitles.list}
            element={<JobTitlesPage />}
          />
          <Route
            path={routes.admin.jobTitles.new}
            element={<JobTitleCreatePage />}
          />
          <Route
            path={routes.admin.jobTitles.edit}
            element={<JobTitleEditPage />}
          />
          <Route
            path={routes.admin.mainTasks.list}
            element={<MainTasksPage />}
          />
          <Route path={routes.admin.tasks.list} element={<TasksPage />} />
          <Route path={routes.admin.tasks.view} element={<TaskPage />} />
          <Route
            path={routes.admin.videoTutorials.list}
            element={<VideoTutorialsPage />}
          />
          <Route
            path={routes.admin.transcripts.list}
            element={<TranscriptsPage />}
          />
          <Route
            path={routes.admin.translations.list}
            element={<TranslationsPage />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </>
      );
    }

    return <></>;
  }, [authStateValue]);

  const authPages = useMemo(() => {
    if (authStateValue) {
      return (
        <>
          <Route
            path={routes.auth.registrationConfirmation}
            element={<RegistrationConfirmationPage />}
          />
          <Route
            path={routes.auth.deletionConfirmation}
            element={<DeletionConfirmationPage />}
          />
        </>
      );
    }

    return (
      <>
        <Route
          path={routes.auth.registrationConfirmation}
          element={<RegistrationConfirmationPage />}
        />
        <Route
          path={routes.auth.deletionConfirmation}
          element={<DeletionConfirmationPage />}
        />
        <Route
          path={routes.auth.passwordReset}
          element={<PasswordResetPage />}
        />
        <Route
          path={routes.auth.passwordResetConfirmation}
          element={<PasswordResetConfirmationPage />}
        />
        <Route path={routes.auth.login} element={<LoginPage />} />
        <Route path={routes.homepage} element={<LoginPage />} />
        <Route path="*" element={<Navigate to={routes.auth.login} replace />} />
      </>
    );
  }, [authStateValue]);

  if (authStateValue && isPending) {
    return <ProgressFactory variant={ProgressVariant.linear} />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LayoutFactory variant={PageLayoutType.admin} />}>
          {adminPages}
        </Route>
        <Route element={<LayoutFactory variant={PageLayoutType.auth} />}>
          {authPages}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouteProvider;
